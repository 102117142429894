module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-SDV0T5B5C2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IT Tech Blog","short_name":"IT Tech Blog","description":"IT Tech Blog for Data Sicentists. It deals with Data Science and machine learning, and Python. We are also concering about frontend such as javascript, react, react-native, and so on.","start_url":"/","lang":"ko","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","legacy":false,"include_favicon":false,"cache_busting_mode":"query","theme_color_in_head":true,"cacheDigest":"948af840930a9929530b1863dd425e91"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
