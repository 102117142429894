export default {
  colors: {
    text: '#24292e',
    background: '#fff',
    modes: {
      dark: {
        text: '#f4f4e4',
        background: '#141414',
      },
    },
  },
};
